import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import "./App.css";
import Button from '@mui/material/Button';
import ProductDisplay from "./components/ProductDisplay";
import Lottie from "lottie-react";
import delivery from "./delivery.json";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import mapImage from './map.jpeg'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const SwissPhoneNumberRegex = /^(\+41|0|41)?([1-9]{2}|21)(\d{1,2}\s?){3}(\d{2}\s?){2}$/;
const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const options = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 0,
};

function App() {
  const [lastPosition, setLastPosition] = useState(null);
  const [granted, setGranted] = useState(false);
  const [geoGranted, setGeoGranted] = useState(false);
  const [menu, setMenu] = useState({});
  const [tab, setTab] = useState("");
  const [orderId, setOrderId] = useState("000");
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [note, setNote] = useState('/');
  const [messageButton, setMessageButton] = useState('Autoriser geolocalisation 📍');
  const [TBAccess, setTBAccess] = useState(false);


  function send(){
    setOpen(true)
    const data = {
      order : menu.menu.filter((item)=> item.count > 0),
      settings: {
        latitude: lastPosition.latitude,
        longitude: lastPosition.longitude,
        mobile: mobile,
        email:email,
        note: note,
      },
      brand:"TomBeers",
      brand_code:"TB_059kf4f",
      order_id: Math.random().toString(36).substring(2),
      total:menu.menu.filter((item)=> item.count > 0).map((item)=>parseFloat(item.price)*item.count).reduce((a,b)=> a+b,0).toFixed(2),
    }
    console.log(data)

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch('https://brewbox.ai/back-end/create-checkout-session-wl', options)
      .then(response => response.json())
      .then(data =>{ 
        window.open(data['redirect_url'], '_self')
      })
      .catch(error => console.error(error));
    
  }

  function isInZone(latitude, longitude) {
    const polygon = [    
      [46.205000, 6.149667],
      [46.203917, 6.150944],
      [46.203444, 6.153083],
      [46.207472, 6.161694],
      [46.208417, 6.164972],
      [46.212222, 6.173139],
      [46.215361, 6.175806],
      [46.215778, 6.168583],
    ];
  
    let inside = false;
  
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0], yi = polygon[i][1];
      const xj = polygon[j][0], yj = polygon[j][1];
  
      const intersect = ((yi >= longitude) !== (yj >= longitude))
        && (latitude <= (xj - xi) * (longitude - yi) / (yj - yi) + xi);
  
      if (intersect) inside = !inside;
  
      if ((xi === latitude && yi === longitude) || (xj === latitude && yj === longitude)) {
        inside = true;
        break;
      }
    }
  
    return inside;
  }

  const updateItemCount = (updatedItem) => {
    const updatedMenu = menu.menu.map((item) =>
      item.title === updatedItem.title ? { ...item, count: updatedItem.count } : item
    );
    setMenu({ ...menu, menu: updatedMenu });
  };
  

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // rayon de la terre en mètres
    const phi1 = (lat1 * Math.PI) / 180; // conversion en radians
    const phi2 = (lat2 * Math.PI) / 180;
    const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // distance en mètres

    return d;
  };

  function NotificationTest(){
    if ("Notification" in window) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            console.log("Permission granted");
            const notification = new Notification("Example", {
              body:"This is a body",
              data: {price: 5},
              icon: "https://tombeers.xyz/logo.png",
            });
          } else {
            alert(permission)
          }
        })
        .catch((err) => {
          console.log("Permission denied", err);
        });
    }
  }

  function grantGeoloc(){
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        console.log(result)
        if (result.state === 'granted') {
            if(!granted){
              setGranted(true);
              setMessageButton("Veuillez patienter")
              console.log("grant from result granted")
              geoLoop();
            }
            
        } else if (result.state === "prompt") {

          navigator.geolocation.getCurrentPosition((position) => {
              console.log(position)
              if(position.coords){
                if(!granted){
                  setGranted(true);
                  setMessageButton("Veuillez patienter")
                  console.log("grant from result prompt")
                  geoLoop();
                }
              } else{

                setMessageButton("Pas de géolocalisation, vérifiez vos autorisations...")
                alert("L'autorisation de géolocalisation a été refusée. Pour réautoriser, veuillez accéder aux paramètres de votre navigateur.");
              }
            },
            (error) => {console.log(error); setMessageButton("Pas de géolocalisation, vérifiez vos autorisations...")},
            options
          );
        } else if (result.state === 'denied') {
          setMessageButton("Pas de géolocalisation, vérifiez vos autorisations...")
          alert("L'autorisation de géolocalisation a été refusée. Pour réautoriser, veuillez accéder aux paramètres de votre navigateur.");
        }
      });
    } else {
      setMessageButton("Pas de géolocalisation, vérifiez vos autorisations...")
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  }

  async function pullMenu(){
    fetch("https://brewbox.ai/back-end/get-menu-by-brand:TB_059kf4f")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } 
      })
      .then((data) => {
        if (data["qr_activate"]) {
          setMenu(data);
          setTab(data.settings.menu_order[0])
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function geoLoop(){
    
      let intervalId;
      const getPosition = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (lastPosition) {
              const distance = calculateDistance(
                lastPosition.latitude,
                lastPosition.longitude,
                position.coords.latitude,
                position.coords.longitude
              );
              if (distance > 10) {
                console.log("L'utilisateur a bougé de plus de 10 mètres !");
              }
            }
            setLastPosition(position.coords);
            console.log(position.coords)
            if(isInZone(position.coords.latitude, position.coords.longitude)){
              console.log("OK zone d'achat")
              setMessageButton("Veuillez patienter...")
              setGeoGranted(true)
            } else {
              setMessageButton("Vous n'êtes pas dans la zone de commande...")
              console.log("NOT zone d'achat")
              if(TBAccess){
                setGeoGranted(true)
              } else{
                setGeoGranted(false)
              }
            }
            
          },
          (error) => {
            console.log(error)
            setMessageButton("Géolocalisation non disponible...")
          },
          options
        );
      };

      getPosition();
      intervalId = setInterval(getPosition, 10000);

      return () => {
        clearInterval(intervalId);
      };
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if(query.get("tb") === "true"){
      setTBAccess(true);
    }

    if (query.get("lat") && query.get("long") && query.get("id") && query.get("success")==="true"){
      setLastPosition({latitude:query.get("lat"), longitude:query.get("long")})
      setOrderId(query.get("id"))
      setOpenInfo(true)
    } else{
      pullMenu();
    }
    
  }, []);

  return (
    <div className="App">
      <Dialog open={openInfo} style={{textAlign:"center"}}>
        <DialogTitle style={{color:"#0ddb29"}}>Paiement réussi! 🎉</DialogTitle>
        <DialogContent>
        <Stack direction="column" spacing={3} alignItems={"center"} sx={{p:1}}> 
          <Lottie
            animationData={delivery}
            style={{ width: "25vmax" }}
            loop={true}
          />

          <h1>ID de commande #{orderId}</h1>
          <h2>Le livreur est en train d'arriver à la position:</h2>

          <Button onClick={()=> {
                const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lastPosition.latitude},${lastPosition.longitude}&output=embed`;

                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                  window.location.href = mapsUrl;
                } else {
                  window.open(mapsUrl, '_blank');
                }
          }} variant="outlined">Voir sur GoogleMaps 📍</Button>

          <h2 style={{opacity:"0.7"}}>Temps estimé : 10 à 15 minutes</h2>
          <h3>📞 Numéro livreur: +4176894954</h3>

          </Stack>
          
        </DialogContent>
      </Dialog>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Vérifiez votre commande</DialogTitle>
        <DialogContent>
          <div style={{opacity:"0.7"}}>
            {menu.menu && menu.menu.filter((item)=> item.count > 0).map((item,i)=>
              <Stack direction="row" key={i}
                      spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                <h4>{item.count} x {item.title}</h4>
                <h4>{item.price * item.count}</h4>
              </Stack>
            )}
            {menu.menu && menu.menu.filter((item)=> item.count > 0).length > 0 ?
                <h2 style={{textAlign:"right", width:"100%", marginTop:"15px", borderTop:"solid 1px grey", paddingTop:"15px"}}>Total {menu.menu.filter((item)=> item.count > 0).map((item)=>parseFloat(item.price)*item.count).reduce((a,b)=> a+b,0).toFixed(2)} CHF</h2>
              :
                ""
            }
          </div>

          <h4 onClick={()=> {
                const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lastPosition.latitude},${lastPosition.longitude}&output=embed`;

                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                  window.location.href = mapsUrl;
                } else {
                  window.open(mapsUrl, '_blank');
                }
                
              }} style={{margin:"25px 0", padding:"7px 20px", borderRadius:"50px", border:"solid 1px rgba(0,0,0,0.1)", textAlign:"center"}}>
                📍 Votre position actuelle
          </h4>

          <TextField
            margin="dense"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            fullWidth
            variant="standard"
            helperText="*obligatoire - veuillez entrer un email valide pour recevoir votre confirmation de paiement."
          />
          <TextField
            margin="dense"
            label="Mobile"
            type="tel"
            fullWidth
            variant="standard"
            value={mobile}
            onChange={(e) => setMobile(e.target.value.trim())}
            helperText="*obligatoire - veuillez entrer un numéro de téléphone suisse valide pour que le livreur puisse vous joindre si besoin."
          />
          <TextField
            margin="dense"
            label="Note / Précision sur votre position"
            type="text"
            fullWidth
            variant="standard"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            helperText="*facultatif"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{color:"rgb(150,150,150)"}} onClick={() => setOpen(false)}>ANNULER</Button>
          {
            SwissPhoneNumberRegex.test(mobile) && EmailRegex.test(email) ?
              <Button sx={{color:"rgb(228,171,81)",padding:"7px 20px", borderRadius:"50px", border:"solid 1px rgba(0,0,0,0.1)"}} onClick={send}>PAYER 💳</Button>
              :
              ""
          }
          
        </DialogActions>
      </Dialog>

      <Stack direction="row" sx={{p:1}}
              spacing={2} alignItems={"center"} justifyContent={"space-between"}>
        <img src="https://tombeers.xyz/logo.png" alt="Logo" width={"60vmax"}/>
        <h2>TomBeers Delivery 📦</h2>
      </Stack>

      {
        menu.menu && geoGranted?
          <Stack direction="column" spacing={2} alignItems={"center"} sx={{p:1}}>  

            <h3>⬇️ Glissez vers le bas pour voir les produits.</h3>

            <Stack direction="row" spacing={0} style={{margin:"20px 0", width:"100%", textAlign:"center"}} flexWrap="wrap" justifyContent={"center"} >
              {menu.settings.menu_order.map((item,i)=>
                        <div key={i} onClick={() => {
                                                        setTab(item);
                                                    }}
                              style={{  color: tab === item? "white" : "rgba(0,0,0,0.6)",
                                        backgroundColor: tab === item? "rgb(228,171,81)" : "",
                                        border: tab === item? "" : "solid 1px rgba(0,0,0,0.2)",

                                        display:"flex", alignItems:"center", justifyContent:"center",
                                        fontWeight:550, textTransform:"uppercase", maxWidth:"100px",
                                        fontSize:"10px", margin:"3px", padding:"2px", width:"20%", height:"60px",
                                        borderRadius:"5px" }}>

                          { menu.menu.filter((cat)=> cat.category === item && cat.count > 0).length > 0 ?
                            <p>{item + " 🟢"}</p>
                            
                            :
                            <p>{item}</p>
                          } 
                        </div>
              )}
            </Stack> 

            <h2 style={{color:"rgb(228,171,81)", marginBottom:"20px"}} >{menu.menu.filter((item)=> item.category === tab).length} produits trouvé(s)</h2>

            {menu.menu.filter((item)=> item.category === tab).map((item,i) =>(
             <ProductDisplay 
                key = {item.title}
                price = {item.price}
                category = {item.category}
                volume = {item.volume}
                deg = {item.deg}
                title = {item.title}
                count = {item.count}
                image_url = {item.image_url}
                callback={(item)=>updateItemCount(item)}
             />)
            )}

          </Stack>
        :
          <Stack direction="column" style={{textAlign:"center", padding:"3vmax",marginTop:"2vmax"}} justifyContent="center" spacing={4} alignItems={"center"}>
            <h1>Vous devez être dans la zone de livraison pour pouvoir commander et être livré.</h1>
            <img src={mapImage} alt="Logo" width={"100%"} />
            {granted?
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                >
                  {messageButton}
                </LoadingButton>
                :
                <Button onClick={grantGeoloc} variant="outlined">{messageButton}</Button>
            }
            
          </Stack>
      }

      {menu.menu && geoGranted && menu.menu.filter((item)=> item.count > 0).length > 0 ?
        <div 
        style={{position:"fixed", bottom:0, width:"100%", textAlign:"center", maxWidth:"800px",
                display:"flex", justifyContent:"center"}}>
          <div 
            onClick={() => setOpen(true)}
            style={{width:"100%", textAlign:"center", margin:"10px", boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.3)",
                    background:"rgb(228,171,81)", borderRadius:"5px", padding:"15px 0", textTransform:"uppercase",
                    color:"white", fontWeight:700, letterSpacing:1}}>
            Vérifier Panier • {menu.menu.filter((item)=> item.count > 0).map((item)=>parseFloat(item.price)*item.count).reduce((a,b)=> a+b,0).toFixed(2)} CHF
          </div>
       </div>
       :
       ""
      }
    </div>
  );
}

export default App;
