import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import "../App.css";


export default function ProductDisplay(props) {
  const [count, setCount] = useState(props.count);

  const increment = () => {
    setCount(count + 1);
    props.callback({
      title: props.title,
      price: props.price,
      deg: props.deg,
      image_url:props.image_url,
      volume: props.volume,
      category: props.category,
      count: count + 1,
    });
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
      props.callback({
        title: props.title,
        price: props.price,
        deg: props.deg,
        image_url:props.image_url,
        volume: props.volume,
        category: props.category,
        count: count - 1,
      });
    }
  };

  return (
    <Stack direction="row"
            sx={{marginBottom:"10px", width:"100%"}}
            spacing={2} alignItems={"center"} justifyContent={"space-between"}>

        <Stack direction="row"
            sx={{marginBottom:"10px", width:"100%"}}
            spacing={2} alignItems={"center"}>
                <img src={props.image_url} alt="Logo" width={"70vmax"} />
                <Stack direction="column" spacing={0.5} alignContent={"left"}>  
                    <h4>{props.title}</h4>
                    <h4 style={{opacity:"0.5"}}> {props.deg}% vol. • {props.volume} L</h4>
                    <h4>{props.price} CHF</h4>
                </Stack>
            </Stack>

            <Stack spacing={2} direction="row" justifyContent="center"  alignItems="center" style={{padding:"10px 15px", borderRadius:"50px", border:"solid 2px rgba(0,0,0,0.1)"}}>
                    {count > 0 ? (
                        <RemoveIcon
                            style={{ fontSize:"30px" }}
                            onClick={decrement}/>
                        ) : (
                        <RemoveIcon
                            style={{ opacity:"0.5", fontSize:"30px" }}/>
                    )}          
                    <p style={{
                        width: "0.5vmax",
                        fontSize: "2vmax",
                        fontWeight: 600,
                        textAlign:"center",
                        color:"black"
                    }}>
                        {count}
                    </p>
                    <AddIcon
                        style={{ fontSize:"30px" }}
                        onClick={increment}
                        />
            </Stack>
    </Stack>
  );
}
